<template>
  <gf-base overflow v-loading="loading">
    <el-row :gutter="20" class="mb-5">
      <el-col :md="8">
        <gf-statwidget3 title="Transaction Stats" type="success">
          <template #box-1-1>
            <div class="stat">
              <div class="stat__icon my-1 ml-n2">
                <span class="svg-icon svg-icon-3x svg-icon-dark">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"/>
                      <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
                      <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
                      <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1"/>
                      <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1"/>
                    </g>
                  </svg>
                </span>
                <span class="text-dark font-weight-bold">Draft</span>
              </div>
              <div class="stat__title">
                <span class="text-dark font-weight-bold font-size-h6">
                  {{ $Numeral(data.transactions.draft.count).format('0,0') }}
                </span>
              </div>
            </div>
          </template>
          <template #box-1-2>
            <div class="stat">
              <div class="stat__icon my-1 ml-n2">
                <span class="svg-icon svg-icon-3x svg-icon-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"/>
                      <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
                      <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
                      <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1"/>
                      <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1"/>
                    </g>
                  </svg>
                </span>
                <span class="text-primary font-weight-bold">Active</span>
              </div>
              <div class="stat__title">
                <span class="text-primary font-weight-bold font-size-h6">
                  {{ $Numeral(data.transactions.active.count).format('0,0') }}
                </span>
              </div>
            </div>
          </template>
          <template #box-2-1>
            <div class="stat">
              <div class="stat__icon my-1 ml-n2">
                <span class="svg-icon svg-icon-3x svg-icon-success">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"/>
                      <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
                      <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
                      <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1"/>
                      <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1"/>
                    </g>
                  </svg>
                </span>
                <span class="text-success font-weight-bold">Finalized</span>
              </div>
              <div class="stat__title">
                <span class="text-success font-weight-bold font-size-h6">
                  {{ $Numeral(data.transactions.finalized.count).format('0,0') }}
                </span>
              </div>
            </div>
          </template>
          <template #box-2-2>
            <div class="stat">
              <div class="stat__icon my-1 ml-n2">
                <span class="svg-icon svg-icon-3x svg-icon-info">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"/>
                      <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
                      <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
                      <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1"/>
                      <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1"/>
                    </g>
                  </svg>
                </span>
                <span class="text-info font-weight-bold">Fulfilled</span>
              </div>
              <div class="stat__title">
                <span class="text-info font-weight-bold font-size-h6">
                  {{ $Numeral(data.transactions.fulfilled.count).format('0,0') }}
                </span>
              </div>
            </div>
          </template>
        </gf-statwidget3>
      </el-col>
      <el-col :md="16">
        <el-row :gutter="20" class="mb-5">
          <el-col :md="8">
            <gf-statwidget :data="$Numeral(data.transactions.count).format('0,0')" subtitle="Total Transactions" type="primary">
              <template #icon>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
                    <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
                    <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1"/>
                    <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1"/>
                  </g>
                </svg>
              </template>
            </gf-statwidget>
          </el-col>
          <el-col :md="8">
            <gf-statwidget :data="$Numeral(data.transactions.delivery.count).format('0,0')" subtitle="For Delivery" type="info">
              <template #icon>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
                    <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
                    <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1"/>
                    <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1"/>
                  </g>
                </svg>
              </template>
            </gf-statwidget>
          </el-col>
          <el-col :md="8">
            <gf-statwidget :data="$Numeral(data.transactions.invoice.count).format('0,0')" subtitle="Pending Invoice" type="warning">
              <template #icon>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
                    <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
                    <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1"/>
                    <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1"/>
                  </g>
                </svg>
              </template>
            </gf-statwidget>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :md="12">
            <gf-statwidget2 type="success" title="Sales" subtitle="Sales For This Company" :data="'₱' + $NumberFormat(data.sales.amount)"></gf-statwidget2>
          </el-col>
          <el-col :md="12">
            <gf-statwidget2 type="danger" title="Pending Invoices" subtitle="Total Pending Invoices" :data="'₱' + $NumberFormat(data.invoices.amount)"></gf-statwidget2>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="mb-5">
      <el-col :span="8">
        <gf-listwidget2 title="Pending Invoices" subtitle="List of Pending Invoices" :items="pendingInvoices" light @row-click="readInvoice">
        </gf-listwidget2>
      </el-col>
      <el-col :span="8">
        <gf-listwidget2 title="Pending Delivery" subtitle="List of Pending Deliveries" :items="pendingDeliveries" light @row-click="readDelivery">
        </gf-listwidget2>
      </el-col>
      <el-col :span="8">
        <gf-listwidget2 title="Top Products" subtitle="Top Product Ordered By This Company" :items="products" light @row-click="readProduct">
        </gf-listwidget2>
      </el-col>
    </el-row>
    <gf-card title="Transaction" subtitle="List of Transaction" style="height: 600px">
      <gf-table :data="salesOrders" @row-click="readSalesOrder">
        <el-table-column v-if="columnFilter[0].show" label="Order No." min-width="110" prop="so_number">
        </el-table-column>
        <el-table-column v-if="columnFilter[1].show" label="Company Name" min-width="200" prop="customer.name">
        </el-table-column>
        <el-table-column v-if="columnFilter[2].show" label="Status" min-width="130">
          <template slot-scope="scope">
            <gf-label inline pill type="dark" v-if="scope.row.status === 1">Draft</gf-label>
            <gf-label inline pill type="primary" v-if="scope.row.status === 2">Active</gf-label>
            <gf-label inline pill type="success" v-if="scope.row.status === 3">Finalized</gf-label>
            <gf-label inline pill type="info" v-if="scope.row.status === 4">Fulfilled</gf-label>
            <gf-label inline pill type="warning" v-if="scope.row.status === 5">Void</gf-label>
            <gf-label inline pill type="danger" v-if="scope.row.status === 6">Deleted</gf-label>
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[3].show" label="Packed" min-width="110" align="center">
          <template slot-scope="slot">
            <div class="circle" :class="[slot.row.packed === 1 ? 'circle--half' : '', slot.row.packed === 2 ? 'circle--full' : '']"></div>
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[4].show" label="Fulfilled" min-width="120" align="center">
          <template slot-scope="slot">
            <div class="circle" :class="[slot.row.fulfilled === 1 ? 'circle--half' : '', slot.row.fulfilled === 2 ? 'circle--full' : '']"></div>
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[5].show" label="Invoiced" min-width="120" align="center">
          <template slot-scope="slot">
            <div class="circle" :class="[slot.row.invoiced === 1 ? 'circle--half' : '', slot.row.invoiced === 2 ? 'circle--full' : '']"></div>
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[6].show" label="Paid" min-width="80" align="center">
          <template slot-scope="slot">
            <div class="circle" :class="[slot.row.paid === 1 ? 'circle--half' : '', slot.row.paid === 2 ? 'circle--full' : '']"></div>
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[7].show" label="Returning" min-width="150">
        </el-table-column>
        <el-table-column v-if="columnFilter[8].show" label="Returned" min-width="150">
        </el-table-column>
        <el-table-column v-if="columnFilter[9].show" label="Order Total" min-width="150">
          <template slot-scope="slot">
            {{ $NumberFormat(slot.row.total) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[10].show" label="Assignee Name" min-width="160">
          <template slot-scope="slot">
            {{ slot.row.assigned_to.fname }}  {{ slot.row.assigned_to.lname }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[11].show" label="Ship From" min-width="150" prop="ship_from.name">
        </el-table-column>
        <el-table-column v-if="columnFilter[12].show" label="Email" min-width="150" prop="email">
        </el-table-column>
        <el-table-column v-if="columnFilter[13].show" label="Reference" min-width="150" prop="reference">
        </el-table-column>
        <el-table-column v-if="columnFilter[14].show" label="Issued At" min-width="140">
          <template slot-scope="slot">
            {{ $DateFormat(slot.row.issue_date) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[15].show" label="Created Date" min-width="150">
          <template slot-scope="slot">
            {{ $DateFormat(slot.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[16].show" label="Ship by Date" min-width="150">
          <template slot-scope="slot">
            {{ $DateFormat(slot.row.shipment_date) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[17].show" label="Last Updated" min-width="150">
          <template slot-scope="slot">
            {{ $DateFormat(slot.row.updated_at) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[18].show" label="Notes" min-width="150" prop="message">
        </el-table-column>
      </gf-table>
      <template #footer>
        <gf-pagination :pagination="pagination" @change="getSalesOrders"></gf-pagination>
      </template>
    </gf-card>
  </gf-base>
</template>

<style lang="scss" scoped>
.stat {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  &__icon {
    display: flex;
    align-items: center;

    .value {
      flex-grow: 1;
      text-align: right;
    }
  }
}

.circle {
  width: 18px;
  height: 18px;
  border: 2px solid #585d64;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;

  &--half {
    background: linear-gradient(90deg, #585d64 50%, #fff 50%);
  }

  &--full {
    background: #585d64;
  }
}
</style>

<script>
import TransactionService from '@/services/v1/Transaction'
import SalesOrder from '@/services/v1/SalesOrder'
import CompanyService from '@/services/v1/Company'

export default {
  data () {
    return {
      loading: false,
      name: '',
      pendingInvoices: [],
      pendingDeliveries: [],
      products: [],

      // data
      data: {
        transactions: {
          count: 0,
          draft: {
            count: 0
          },
          active: {
            count: 0
          },
          finalized: {
            count: 0
          },
          fulfilled: {
            count: 0
          },
          delivery: {
            count: 0
          },
          invoice: {
            count: 0
          }
        },
        sales: {
          amount: 0
        },
        invoices: {
          amount: 0,
          data: []
        },
        deliveries: {
          data: []
        },
        products: {
          data: []
        }
      },
      salesOrders: [],

      filter: {
        'so.customer_id': {
          '=': this.$route.params.id
        }
      },
      pagination: {
        total: 0,
        page: {
          number: 1,
          size: 25
        }
      },

      columnFilter: [
        { label: 'Order No.', show: true },
        { label: 'Company Name', show: false },
        { label: 'Status', show: true },
        { label: 'Packed', show: true },
        { label: 'Fulfilled', show: true },
        { label: 'Invoiced', show: true },
        { label: 'Paid', show: true },
        { label: 'Returning', show: false },
        { label: 'Returned', show: false },
        { label: 'Order Total', show: true },
        { label: 'Assignee Name', show: false },
        { label: 'Ship From', show: false },
        { label: 'Email', show: false },
        { label: 'Reference', show: false },
        { label: 'Issued At', show: false },
        { label: 'Created Date', show: false },
        { label: 'Ship by Date', show: true },
        { label: 'Last Updated', show: false },
        { label: 'Notes', show: false }
      ]
    }
  },
  methods: {
    readInvoice (index) {
      const invoice = this.data.invoices.data[index]
      this.$router.push({ name: 'invoice-view', params: { id: invoice.sales_order_id, iid: invoice.id } })
    },
    readDelivery (index) {
      const delivery = this.data.deliveries.data[index]
      this.$router.push({ name: 'shipment-view', params: { id: delivery.sales_order_id, sid: delivery.id } })
    },
    readProduct (index) {
      const product = this.data.products.data[index].variant
      this.$router.push({ name: 'product-variant-detail', params: { id: product.product.id, vid: product.id } })
    },
    readSalesOrder (row) {
      this.$router.push({ name: 'order-view', params: { id: row.id } })
    },
    async getTransaction () {
      try {
        this.loading = true

        const trService = new TransactionService(this.$route.params.id)
        const result = await trService.get()

        this.data = result.data
        const type = ['dark', 'primary', 'secondary', 'success', 'info', 'warning', 'danger']

        for (let i = 0; i < this.data.invoices.data.length; i++) {
          const item = this.data.invoices.data[i]

          const due = this.$DateTime.fromISO(item.payment_due)
          const date = this.$DateTime.local()
          const diff = date.diff(due, 'days').toObject()

          const invoice = {
            id: item.id,
            header: item.si_number,
            subheader: `Overdue: ${Math.floor(diff.days > 0 ? diff.days : 0)} Days (${this.$DateFormat(item.payment_due)})`,
            quantity: '₱' + this.$NumberFormat((item.total - item.paid)),
            type: type[Math.floor(Math.random() * Math.floor(6))]
          }
          this.pendingInvoices.push(invoice)
        }

        for (let i = 0; i < this.data.deliveries.data.length; i++) {
          const item = this.data.deliveries.data[i]
          const filtered = this.pendingDeliveries.filter(f => f.header === item.order.so_number)
          const delivery = {
            id: item.id,
            header: item.order.so_number,
            subheader: 'Shipment #' + (filtered.length + 1),
            quantity: 'Qty: ' + item.units,
            type: type[Math.floor(Math.random() * Math.floor(6))]
          }
          this.pendingDeliveries.push(delivery)
        }

        for (let i = 0; i < this.data.products.data.length; i++) {
          const item = this.data.products.data[i]
          const product = {
            id: item.variant.id,
            header: this.$StringFormat(item.variant.product.name + ' - ' + item.variant.name, 25),
            subheader: item.variant.sku,
            quantity: 'Qty: ' + item.quantity,
            type: type[Math.floor(Math.random() * Math.floor(6))]
          }
          this.products.push(product)
        }
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    },
    async getSalesOrders () {
      try {
        const soService = new SalesOrder()
        const result = await soService.list(this.pagination.page, null, this.filter)
        this.salesOrders = result.data.rows
        if (result.data.count === 0 && result.data.rows.length !== 0) {
        } else this.pagination.total = result.data.count
      } catch (error) {
        this.$Error(error)
      }
    },
    async getCompany () {
      try {
        const cmService = new CompanyService(this.$route.params.id)
        const result = await cmService.get()
        this.name = result.data.name
      } catch (error) {
        this.$Error(error)
      }
    }
  },
  async mounted () {
    this.loading = true
    await this.getCompany()
    await this.getTransaction()
    await this.getSalesOrders()

    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Transaction' },
      { title: this.name }
    ])
  }
}
</script>
